<script lang="ts">
  import { twMerge } from 'tailwind-merge';

  let clazz: string = '';
  export { clazz as class };
  export let src: string | null | undefined = undefined;
  export let initial: string;
  let img: HTMLImageElement;
  let avatarLoadError = false;

  $: {
    if (src) avatarLoadError = false;
  }
</script>

{#if src && !avatarLoadError}
  <img
    class={twMerge('object-contain mx-auto my-auto rounded-full focus:border-blue', clazz)}
    bind:this={img}
    {src}
    on:load={() => {
      if (img.src === src) avatarLoadError = false;
    }}
    on:error={() => {
      avatarLoadError = true;
    }}
    alt="User avatar"
  />
{:else}
  <div
    class={twMerge(
      `flex
      mx-auto
      my-auto
          text-center
          rounded-full
          text-gray-600
          text-4xl
          bg-blue-50
          leading-relaxed
          overflow-hidden
          tracking-tighter
          `,
      clazz
    )}
  >
    <div class="self-center flex-grow">
      {initial}
    </div>
  </div>
{/if}
